// eslint-disable-next-line no-redeclare
/* global coreui */

/**
 * --------------------------------------------------------------------------
 * CoreUI Boostrap Admin Template (v3.2.0): popovers.js
 * Licensed under MIT (https://coreui.io/license)
 * --------------------------------------------------------------------------
 */

$(document).on('click', '[data-toggle="popover"]', event => {
  const $popover = $(event.currentTarget)
  $popover.popover('show')
})
$('[data-toggle="popover-modal"]').on('click', event => {
  const $popover = $(event.currentTarget)
  $popover.popover('show')
  event.stopPropagation()
})
// // モーダルエラー応急処置
// $('[data-toggle="popover"]').popover('show').popover('hide')

$('html').on('mouseup', e => {
  const l = $(e.target)
  if (l.closest('.popover').length === 0) {
    $('.popover').each(function () {
      $(this).popover('hide')
    })
  }
})

// バブリング停止
// $(document).on('click', '[data-toggle="popover"]', event => {
//   event.stopPropagation()
// })

